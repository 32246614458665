
   const TextWithLineBreaks = ({ text }) => {
    // Split the input text into an array of lines based on the '\n' delimiter
    if (!text) {
        return null; // Render nothing if the description is null or undefined
      }
    const lines = text.split('\n');
  
    // Use map to wrap each line with <div> or <p> tags and add a <br> tag inside it
    const linesWithBr = lines.map((line, index) => (
      <div key={index}>
        {line}
        {index !== lines.length - 1 && <br />} {/* Add <br> for all lines except the last one */}
      </div>
    ));
  
    return <><div>{linesWithBr}</div></>;
  };
  
const BolViewTableContent = ({content, perticularData}) => {


    return(
        <>
            <table  cellPadding="0" cellSpacing="0" border="0" style={{margin:"auto; width: 100%"}} onClick={e => e.stopPropagation()}>
                <tbody>
        <tr>
            <td style={{width:"45%",padding:"40px 0 0",border:"none"}}><img style={{display:"none"}} src="/images/logo.jpg" alt="Qlines" width="140"/></td>
            <td style={{fontSize: '18px', fontWeight: '600',padding: '0px 0px 0px',fontFamily: 'Euclid Circular A',border:"none"}}>
            <p style={{fontSize: '18px', margin:0,fontWeight: 600,padding: '10px 0 0 0px',fontFamily: 'Euclid Circular A'}}>{content.shipment_type}</p>
        
            </td>
           
        </tr>
        <tr>
            <td colSpan="2" style={{padding: "0px 0 0"}}>
                <table style={{width: '100%', tableLayout:'fixed'}}className="bordered-table-main main-table">
                    <tbody>
                    <tr>
                        <td rowSpan="2" colSpan="2"  className="td-padding bl-none valign-top textALign-left">
                            <p className="td-headings">Shipper/Exporter<sup className="mandatory"></sup> (Complete Name and Address) </p>
                            {/* <p>{content.shipper?.company_name}</p>
                            <p> <TextWithLineBreaks text={content.shipper?.address}/></p>
                            <p>{content.shipper?.city}</p>
                            <p>{content.shipper?.state}</p>
                            <p>{content.shipper?.country_name}</p>
                            <p>{content.shipper?.contact_number? "Ph:":''} {content.shipper?.contact_number}</p> */}
                            <p><TextWithLineBreaks text={content?.shipper_details}/></p>
                        </td>
                        <td className="td-padding valign-top">
                            <p className="td-headings">Booking Number</p>
                            <p>{content.booking_number}</p></td>
                        <td  className="td-padding br-none valign-top">
                            <p className="td-headings">Bill of Lading Number</p>
                            <p>{content.bol_number}</p></td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="td-padding br-none valign-top">
                            <p className="td-headings">Export References</p>
                            <p>{content.export_references}</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" colSpan="2"  className="td-padding bl-none valign-top textALign-left">
                            <p className="td-headings">Consignee<sup className="mandatory"></sup> (Complete Name and Address)</p>
                            {/* <p>{content.consignee?.company_name}</p>
                            <p> <TextWithLineBreaks text={content.consignee?.address} /></p>
                            <p>{content.consignee?.city}</p>
                            <p>{content.consignee?.state}</p>
                            <p>{content.consignee?.country_name}</p>
                            <p>{content.consignee?.contact_number? "Ph:":''} { content.consignee?.contact_number}</p> */}
                            <p><TextWithLineBreaks text={content?.consignee_details}/></p>
                        </td>
                        <td className="td-padding br-none valign-top" colSpan="2">
                            <p className="td-headings">Forwarding Agent  F.M.C Number</p>
                            <p>{content.forwarding_agent_fmc_number}</p>
                        </td>	
                    </tr>
                    <tr>
                        <td colSpan="2" className="td-padding br-none valign-top">
                            <p className="td-headings">Point and  Country of Origin of Goods</p>
                            <p>{content.country_of_origin_goods}</p>
                        </td>
                    </tr>
                    
                    <tr style={{height:"125px"}}>
                        <td colSpan="2" className="td-padding bl-none valign-top">
                            <p className="td-headings">Notify Party (Complete Name and Address)</p>
                            {/* <p>{content.notify_party?.company_name}</p>
                            <p> <TextWithLineBreaks text={content.notify_party?.address} /></p>
                            <p>{content.notify_party?.city}</p>
                            <p>{content.notify_party?.state}</p>
                            <p>{content.notify_party?.country_name}</p>
                            <p>{content.notify_party?.contact_number? "Ph:":''} { content.notify_party?.contact_number}</p> */}
                            <p><TextWithLineBreaks text={content?.notify_party_details}/></p>
                        </td>
                        <td colSpan="2" className="td-padding br-none valign-top">
                            <p className="td-headings">For Delivery please apply to</p>
                            <TextWithLineBreaks text={content.delivery_apply_to} />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-padding bl-none valign-top">
                            <p className="td-headings">Pre-Carriage By</p>
                            <p>{content.pre_carriage_by}</p>
                        </td>
                        <td className="td-padding valign-top">
                            <p className="td-headings">Place of Receipt by Pre-carrier</p>
                            <p>{content.pre_carrier_reciept_place}</p>
                        </td>
                        <td colSpan="2" className="td-padding br-none valign-top">
                            
                        </td>
                    </tr>
                    <tr>
                        <td className="td-padding bl-none valign-top">
                            <p className="td-headings">Export Carrier <small>(Vessels/Voy/Flag)</small></p>
                            <p>{content.export_carrier}</p>
                        </td>
                        <td className="td-padding valign-top">
                            <p className="td-headings">Port of Loading</p>
                            <p>{content?.port_of_loading}</p>
                        </td>
                        <td colSpan="2" className="td-padding br-none valign-top">
                            <p className="td-headings">Loading Pier/Terminal</p>
                            <p>{content.loading_pier_terminal}</p>

                        </td>
                    </tr>
                    <tr>
                        <td className="td-padding bl-none valign-top">
                            <p className="td-headings">Port of Discharge <sup className="mandatory"></sup></p>
                            <p>{content?.port_of_discharge}</p>
                        </td>
                        <td className="td-padding valign-top">
                            <p className="td-headings">Place of Delivery by On Carrier</p>
                            <p>{content.carrier_place_delivery}</p>
                        </td>
                        <td colSpan="2" className="td-padding br-none valign-top">
                            <p className="td-headings">Number of Orginals</p>
                            <p>{content.number_of_originals}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" style={{padding: '10px 0 0px 15px'}} className="br-none bl-none valign-top">
                            <p className="td-headings" style={{textAlign: 'center',textTransform: 'uppercase'}}>Particulars Furnished by Shipper</p>
                            <table style={{width: '100%'}} className="bordered-table-main sub-table perticular-table">
                                <thead>
                                    <tr>
                                        <th  className="td-padding-sub bl-none valign-top" style={{textAlign:'left'}}>MRKS & NOS/CNTR</th>
                                        <th  className="td-padding-sub valign-top"># OF PKGS</th>
                                        <th  className="td-padding-sub valign-top" style={{width: '37%'}}>Description of Packages</th>
                                        <th  className="td-padding-sub valign-top">Gross Weight</th>
                                        <th  className="td-padding-sub valign-top br-none">Measurement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { perticularData?.map((perticular, index)=> 
                                        (
                                            <tr key={index} style={{height: '185px'}}>
                                            <td  className="td-padding-sub bl-none bb-none valign-top" > <TextWithLineBreaks text={perticular?.container_number} /></td>
                                            <td  className="td-padding-sub valign-top bb-none"> <TextWithLineBreaks text={perticular?.number_of_packages} /></td>
                                            <td  className="td-padding-sub valign-top bb-none"> <TextWithLineBreaks text={perticular?.description_of_packages} /></td>
                                            <td  className="td-padding-sub valign-top bb-none"> <TextWithLineBreaks text={perticular?.gross_weight} /></td>
                                            <td  className="td-padding-sub valign-top br-none bb-none"> <TextWithLineBreaks text={perticular?.measurement} /></td>
                                        </tr>
                                        )
                                    
                                    
                                        )}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" style={{padding:"padding: 15px 0 15px 15px"}} className="br-none bl-none valign-top">
                            <p style={{textTransform: "uppercase"}}>Shippers Declared Values</p>
                            <p>Subject to Extra Freight as per our Tariff and Clauses of this B/L</p>
                            <table style={{width: '100%'}} className="bordered-table-main sub-table">
                                <thead>
                                    <tr>
                                        <th  className="td-padding-sub bl-none valign-top" style={{width: '30%',textAlign:"left"}}>Freight charges </th>
                                        <th  className="td-padding-sub valign-top" style={{width: '14%'}}>Basics</th>
                                        <th  className="td-padding-sub valign-top" style={{width: '16%'}}>Rate</th>
                                        <th  className="td-padding-sub valign-top">Prepaid</th>
                                        <th  className="td-padding-sub br-none valign-top">Collect</th>
                                    </tr>
                                </thead>
                                <tbody className="default-height">
                                {content.shippers_declared_values.map((shipper, index)=> (
                                    <tr key={index} style={{borderBottom: "1px solid black "}}>
                                        <td  className="td-padding-sub bl-none valign-top  bt-none"><div className="default-height"> <TextWithLineBreaks text={shipper?.freight_charges} /></div></td>
                                        <td  className="td-padding-sub valign-top bb-none bt-none"><div className="default-height"> <TextWithLineBreaks text={shipper?.basics} /></div></td>
                                        <td  className="td-padding-sub valign-top bb-none bt-none"><div className="default-height"> <TextWithLineBreaks text={shipper?.rate} /></div></td>
                                        <td  className="td-padding-sub valign-top bb-none bt-none"><div className="default-height"> <TextWithLineBreaks text={shipper?.prepaid} /></div></td>
                                        <td  className="td-padding-sub valign-top br-none bb-none bt-none"><div className="default-height"> <TextWithLineBreaks text={shipper?.collect} /></div></td>
                                    </tr>
                                ))}
                                 {content.shippers_declared_values.length === 0 ?<tr style={{height: '3vw'}}></tr>:''}
                                
                                    <tr>
                                        <td colSpan="3" rowSpan="3" className="bl-none bb-none td-padding valign-top">
                                            <p style={{whiteSpace: "normal"}}><strong>RECEIVED</strong> by the Carrier the Goods as specified above in apparent good order and condition unless otherwise stated, to be transported to such place as agreed, authorized or permitted herein and subject to all the terms and conditions appearing on the front and reverse of this Bill of Lading to which the Merchant agrees by accepting this Bill of Lading, and local privileges and customers not withstanding. The particulars given above as stated by the shipper and the weight, measure, quantity, condition, contents and value of the Goods are unknown to the Carrier.<br/>
                                            In WITNESS whereof three (3) original Bills of Lading have been signed if not otherwise stated above, the same being accomplished the other(s), if any, to be void. If required by the Carrier one (1) original Bill of Lading must be surrendered duly endorsed in exchange for the Goods or delivery order.</p>
                                        </td>
                                        <td className="td-padding valign-top"><p className="td-headings">Prepaid Total</p>
                                            <p>{content.prepaid_total}</p></td>
                                        <td className="br-none td-padding valign-top" style={{height: '70px'}}>
                                            <p className="td-headings">Collect Total</p>
                                            <p>{content.collect_total}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-padding valign-top" style={{height: '70px'}}>
                                            <p className="td-headings">Issued At</p>
                                            <p>{content?.issued_at}</p>
                                        </td>
                                        <td  className="br-none td-padding valign-top" style={{height: '70px'}}>
                                            <p className="td-headings">ON</p>
                                            <p>{content?.issued_on}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        {/* <td className="bb-none br-none td-padding valign-top" colSpan="2">
                                            <p className="td-headings">Issued By</p>
                                            <p style={{padding: '10px 0'}}>{content.content.issued_by}</p>
                                        </td> */}
                                        <td className="bb-none br-none td-padding valign-top" colSpan="2">
                                            <p className="td-headings">Issued By</p>
                                            <p style={{padding: "0px 0 2px"}}>{content.issued_by}</p>
                                            <p style={{padding: "0px 0 10px",fontWeight: "bold",fontStyle: "italic"}}>{content.issued_by_as}</p>
                                           
    
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>

        </>
    )
}

export default BolViewTableContent